import React, { Suspense, lazy } from 'react'

import { IntlProvider } from 'react-intl'
import { Routes, Route } from 'react-router-dom'
import $ from 'jquery'
import Cookies from 'js-cookie'

import { StokrLoader } from '@stokr/components-library'

const Home = lazy(() => import('pages/home'))
const NewVenture = lazy(() => import('pages/new-venture'))
const Careers = lazy(() => import('pages/careers'))
const ProductOwner = lazy(() =>
  import('pages/careers/job-postings/product/ProductOwner'),
)

const ComplianceOfficer = lazy(() =>
  import('pages/careers/job-postings/complianceOfficer'),
)

const TechWriter = lazy(() =>
  import('pages/careers/job-postings/technicalWriter'),
)

const FrontendDeveloper = lazy(() =>
  import('pages/careers/job-postings/juniorFrontEnd'),
)

const PressReleases = lazy(() => import('pages/press-releases'))

const Legal = lazy(() => import('pages/legal'))
const Privacy = lazy(() => import('pages/privacy'))
const Terms = lazy(() => import('pages/terms'))
const CookiePolicy = lazy(() => import('pages/cookie-policy'))

const StokePost = lazy(() => import('pages/stoke-post'))
const StokePostArticle = lazy(() =>
  import('pages/stoke-post/stoke-post-article'),
)
const Team = lazy(() => import('pages/team/Team'))

const Memberships = lazy(() => import('pages/memberships'))
const IntroductionToWalletsAndKeys = lazy(() =>
  import('pages/101/blockchain/introduction-to-wallets-and-keys'),
)
const TheDifferentWalletTypes = lazy(() =>
  import('pages/101/blockchain/the-different-wallet-types'),
)
const WalletsSupportedBySTOKR = lazy(() =>
  import('pages/101/blockchain/wallets-supported-by-stokr'),
)
const GettingEther = lazy(() => import('pages/101/blockchain/getting-ether'))
const StayingSafe = lazy(() => import('pages/101/blockchain/staying-safe'))
const WhatIsAssetTokenisation = lazy(() =>
  import('pages/101/asset-tokenisation/WhatIsAssetTokenisation'),
)
const TokenisationOverview = lazy(() =>
  import('pages/101/asset-tokenisation/WhyTokenization'),
)
const InvestorWhitelisting = lazy(() =>
  import('pages/101/asset-tokenisation/InvestorWhitelisting'),
)

const PageNotFound = lazy(() => import('pages/404'))
const Page404 = lazy(() => import('pages/page404'))
const UnderConstruction = lazy(() => import('pages/under-construction'))

const Newsletter = lazy(() => import('pages/newsletter'))
const Products = lazy(() => import('pages/products'))

class AppRouter extends React.Component {
  componentDidMount() {
    $(document).ready(function () {
      const isTermsAccepted_onClick = Cookies.get('termsaccepted_onclick')

      setTimeout(() => {
        if (!isTermsAccepted_onClick) {
          $('button, a, .clickable').click(function (event) {
            if (!isTermsAccepted_onClick) {
              const d = new Date()
              const exdays = 365
              d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
              const expires = 'expires=' + d.toUTCString() + ';'
              const cookieValue = 'termsaccepted=true; ' + expires

              const cookieOptions = {
                expires: exdays,
              }

              if (process.env.NODE_ENV === 'production') {
                cookieOptions.domain = '.stokr.io'
              }

              Cookies.set('termsaccepted_onclick', cookieValue, cookieOptions)
            }
          })
        }
      }, 1000)
    })
  }

  render() {
    const redirectToCareersPage = () => {
      if (
        window.location.href.indexOf('hiring') > -1 ||
        window.location.href.indexOf('venture-secretary') > -1
      ) {
        window.location.href = '/careers'
      }
      return
    }

    const redirectToSTOArticle = () => {
      if (
        window.location.href.indexOf(
          'how-to-launch-a-security-token-offering-in-europe',
        ) > -1
      ) {
        window.location.href = '/stoke-post/security-token-offerings'
      }
      return
    }
    return (
      <div>
        <IntlProvider locale="en">
          <Routes>
            <Route exact path="/" element={Await(Home)()} />
            <Route exact path="/products" element={Await(Home)()} />
            <Route exact path="/featured-assets" element={Await(Home)()} />

            <Route
              exact
              path="/featured-assets/:venture"
              element={Await(NewVenture)()}
            />
            <Route exact path="/login" element={<Home isLogin />} />
            <Route exact path="/signup" element={<Home isSignUp />} />

            <Route
              //exact
              path="/manage-user"
              element={
                <Home
                  isResetPassword={window.location.href.includes(
                    'resetPassword',
                  )}
                  isVerifyEmail={window.location.href.includes('verifyEmail')}
                />
              }
            />

            <Route exact path="/careers" element={Await(Careers)()} />

            <Route
              exact
              path="/careers/compliance-officer"
              element={Await(ComplianceOfficer)()}
            />

            <Route
              exact
              path="/careers/technical-writer"
              element={Await(TechWriter)()}
            />

            <Route
              exact
              path="/careers/frontend-developer"
              element={Await(FrontendDeveloper)()}
            />



            <Route
              exact
              path="/careers/technical-product-manager"
              element={Await(ProductOwner)()}
            />

            <Route
              exact
              path="/press-releases"
              element={Await(PressReleases)()}
            />
            <Route
              exact
              path="/press-releases/:url"
              element={Await(StokePostArticle)()}
            />

            <Route exact path="/products/:url" element={Await(Products)()} />
            <Route exact path="/legal" element={Await(Legal)()} />
            <Route exact path="/privacy" element={Await(Privacy)()} />
            <Route exact path="/terms" element={Await(Terms)()} />

            <Route exact path="/team" element={Await(Team)()} />

            <Route exact path="/memberships" element={Await(Memberships)()} />
            <Route
              exact
              path="/cookie-policy"
              element={Await(CookiePolicy)()}
            />

            <Route exact path="/stoke-post" element={Await(StokePost)()} />
            <Route
              exact
              path="/stoke-post/:url"
              element={Await(StokePostArticle)()}
            />
            <Route
              exact
              path="/stoke-post/how-to-launch-a-security-token-offering-in-europe"
              element={redirectToSTOArticle()}
            />

            <Route
              exact
              path="/101"
              element={Await(WhatIsAssetTokenisation)()}
            />
            <Route
              exact
              path="/101/getting-on-the-blockchain"
              element={Await(IntroductionToWalletsAndKeys)()}
            />
            <Route
              exact
              path="/101/getting-on-the-blockchain/introduction-to-wallets-and-keys"
              element={Await(IntroductionToWalletsAndKeys)()}
            />
            <Route
              exact
              path="/101/getting-on-the-blockchain/the-different-wallet-types"
              element={Await(TheDifferentWalletTypes)()}
            />
            <Route
              exact
              path="/101/getting-on-the-blockchain/wallets-supported-by-stokr"
              element={Await(WalletsSupportedBySTOKR)()}
            />
            <Route
              exact
              path="/101/getting-on-the-blockchain/buying-crypto"
              element={Await(GettingEther)()}
            />
            <Route
              exact
              path="/101/getting-on-the-blockchain/staying-safe"
              element={Await(StayingSafe)()}
            />

            <Route
              exact
              path="/101/asset-tokenization"
              element={Await(WhatIsAssetTokenisation)()}
            />

            <Route
              exact
              path="/101/asset-tokenization/what-is-asset-tokenization"
              element={Await(WhatIsAssetTokenisation)()}
            />
            <Route
              exact
              path="/101/asset-tokenization/tokenization-overview"
              element={Await(TokenisationOverview)()}
            />
            <Route
              exact
              path="/101/asset-tokenization/investor-whitelisting"
              element={Await(InvestorWhitelisting)()}
            />

            <Route
              exact
              path="/hiring"
              element={Await(redirectToCareersPage)()}
            />
            <Route
              exact
              path="/venture-secretary"
              element={Await(redirectToCareersPage)()}
            />
            <Route exact path="/404" element={Await(Page404)()} />
            <Route
              exact
              path="/under-construction"
              element={Await(UnderConstruction)()}
            />

            <Route exact path="/newsletter" element={Await(Newsletter)()} />
            <Route
              exact
              path="/page-not-found"
              element={Await(PageNotFound)()}
            />
            <Route path="*" element={Await(PageNotFound)()} />
          </Routes>
        </IntlProvider>
      </div>
    )
  }
}

function Await(Component) {
  return (props) => (
    <Suspense fallback={<StokrLoader />}>
      <Component {...props} />
    </Suspense>
  )
}

export default AppRouter
